<template>
  <div v-if="canAccess('rp_consolidate_enrollments')">
    <BlockUI :blocked="loading" v-if="loading" :fullScreen="true" class="block-ui">
      <i v-if="loading" class="pi pi-spin pi-spinner block-ui-item"></i>
    </BlockUI>
    <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items"/>
    <div class="card">
      <div class="p-col-12">
        <div class="p-text-right p-fluid  p-ml-2">
          <div class="p-grid p-fluid  p-ml-2">
            <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"/>
                      <label for="country">País</label>
                  </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-right: 3px">
                    <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                              id="start_date" :showIcon="true" v-model="dateStart"
                              dateFormat="yy-mm-dd"
                              :yearNavigator="true" yearRange="2019:2040"/>
                    <label for="start_date">Fecha Inicio</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="dateStart" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="dateEnd" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <Button label="Buscar" class="p-button-success  df"
                      @click="getData()"/>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <Button label="Exportar" class="p-button-secondary df"
                      @click="exportReport('XLS')"/>

            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <Button label="Imprimir" class="p-button-info df"
                      @click="exportReport('PDF')"/>

            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div class="card">
          <h4 style="width: 100%">Consolidado diario de Producci&oacute;n</h4>
          <div class="table-responsive">
            <table class="table table-hover" style="width: 100%">
              <thead>
              <tr class="text-center">
                <th rowspan='2' scope="col" class="thC"><b>ZONA</b></th>
                <th rowspan='2' scope="col" class="thC"><b>FILIAL</b></th>
                <th scope="col" class="thC" colspan="2"><b>ARRASTRE</b></th>
                <th scope="col" class="thC" colspan="3"><b>INGRESADAS</b></th>

                <th scope="col" class="thC" colspan="3"><b>OK</b></th>
                <th scope="col" class="thC" colspan="3"><b>RECHAZADAS</b></th>
                <th scope="col" class="thC" colspan="3"><b>REINGRESO</b></th>
                <th scope="col" class="thC" colspan="3"><b>DEVUELTAS</b></th>
                <th scope="col" class="thC"><b> </b></th>
                <th scope="col" class="thC" colspan="2"><b>ACUMULADO AÑO</b></th>
                <th scope="col" class="thC" colspan="3"><b>CONTADO</b></th>


              </tr>
              <tr class="text-center">
                <th scope="col" class="thC"><b>MES</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>


                <th scope="col" class="thC"><b> PENDIENTES </b></th>

                <th scope="col" class="thC"><b>OK</b></th>
                <th scope="col" class="thC"><b>INGR.</b></th>

                <th scope="col" class="thC"><b>DIA</b></th>
                <th scope="col" class="thC"><b>SEM</b></th>
                <th scope="col" class="thC"><b>MES</b></th>

              </tr>
              </thead>
              <tbody class="text-center tableBody">
              <tr v-for="(item,idx) in tbData" :key="idx" :class="{'bold-row': item.zone === '----'}">
                <td>{{ item.zone }}</td>
                <td>{{ item.name }}</td>
                <td class="tdC">{{ item.mat_dragging_month }}</td>
                <td class="tdC">{{ item.mat_dragging_week }}</td>


                <td class="tdC">{{ item.mat_input_day }}</td>
                <td class="tdC">{{ item.mat_input_week }}</td>
                <td class="tdC">{{ item.mat_input_month }}</td>

                <td class="tdC">{{ item.mat_ok_day }}</td>
                <td class="tdC">{{ item.mat_ok_week }}</td>
                <td class="tdC">{{ item.mat_ok_month }}</td>

                <td class="tdC">{{ item.mat_reject_day }}</td>
                <td class="tdC">{{ item.mat_reject_week }}</td>
                <td class="tdC">{{ item.mat_reject_month }}</td>

                <td class="tdC">{{ item.mat_re_input_day }}</td>
                <td class="tdC">{{ item.mat_re_input_week }}</td>
                <td class="tdC">{{ item.mat_re_input_month }}</td>

                <td class="tdC">{{ item.mat_dev_day }}</td>
                <td class="tdC">{{ item.mat_dev_week }}</td>
                <td class="tdC">{{ item.mat_dev_month }}</td>


                <td class="tdC">{{ item.mat_pendent_month }}</td>

                <td class="tdC">{{ item.cumulative_year_ok }}</td>
                <td class="tdC">{{ item.cumulative_year_entered }}</td>

                <td class="tdC">{{ item.mat_cont_day }}</td>
                <td class="tdC">{{ item.mat_cont_week }}</td>
                <td class="tdC">{{ item.mat_cont_month }}</td>


              </tr>
              </tbody>
              <tfoot class="text-center tableBody">
              <tr>
                <td colspan="2"><strong>TOTALES</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_dragging_month') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_dragging_week') }}</strong></td>


                <td class="tdC"><strong>{{ totalItems('mat_input_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_input_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_input_month') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('mat_ok_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_ok_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_ok_month') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('mat_reject_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_reject_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_reject_month') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('mat_re_input_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_re_input_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_re_input_month') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('mat_dev_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_dev_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_dev_month') }}</strong></td>


                <td class="tdC"><strong>{{ totalItems('mat_pendent_month') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('cumulative_year_ok') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('cumulative_year_entered') }}</strong></td>

                <td class="tdC"><strong>{{ totalItems('mat_cont_day') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_cont_week') }}</strong></td>
                <td class="tdC"><strong>{{ totalItems('mat_cont_month') }}</strong></td>
              </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="p-col-12">
        <div class="p-text-right p-fluid  p-ml-2">
          <div class="p-grid p-fluid  p-ml-2">
            <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="country" v-model="country" :options="countries" optionLabel="country"
                                @change="subsidiary=null;itemsEnrollments=[];"
                      />
                      <label for="country">País</label>
                  </span>
            </div>
            <div class="p-col-12 p-md-2 p-mt-2" style="padding-top: 20px;">
                    <span class="p-float-label df" style="margin-right: 1px;">
                      <Dropdown id="subsidiary" v-model="subsidiary" :filter="true" :options="subsidiaries"
                                optionLabel="name"/>
                      <label for="subsidiary">Filial</label>
                  </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-right: 3px">
                    <Calendar autocomplete="off" :maxDate="new Date()" aria-autocomplete="false"
                              id="start_date" :showIcon="true" v-model="dateStart"
                              dateFormat="yy-mm-dd"
                              :yearNavigator="true" yearRange="2019:2040"/>
                    <label for="start_date">Fecha Inicio</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <span class="p-float-label df" style="margin-left: 3px">
                  <Calendar :maxDate="new Date()" :minDate="dateStart" autocomplete="off"
                            id="end_date" :showIcon="true" v-model="dateEnd" dateFormat="yy-mm-dd"
                            :yearNavigator="true" yearRange="2019:2040" class="p-inputtext-sm"/>
                  <label for="end_date">Fecha Fin</label>
              </span>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="Buscar" class="p-button-success"
                      @click="getDataSubsidiary()"/>
            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-1 p-mt-2">
              <Button label="Exportar" class="p-button-secondary "
                      @click="exportReportSubsidiary('XLS')"/>

            </div>
            <div style="padding-top: 20px;" class="p-col-12 p-md-2 p-mt-2">
              <Button label="Imprimir" class="p-button-info"
                      @click="exportReportSubsidiary('PDF')"/>

            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <div class="card">
          <div class="table-responsive">
            <table class="table table-hover" style="width: 100%">
              <thead>
              <tr class="text-center">
                <th scope="col" class="thC"><b>ITEM</b></th>
                <th scope="col" class="thC"><b>MAT.</b></th>
                <th scope="col" class="thC"><b>ASESOR</b></th>
                <th scope="col" class="thC"><b>CARGO</b></th>
                <th scope="col" class="thC"><b>ORG.</b></th>
                <th scope="col" class="thC"><b>ESTRATEGIA</b></th>
                <th scope="col" class="thC"><b>FILIAL</b></th>
                <th scope="col" class="thC"><b>ZONA</b></th>
                <th scope="col" class="thC"><b>VIRTUAL</b></th>
              </tr>
              </thead>
              <tbody class="text-center tableBody">
              <tr v-for="(item,idx) in itemsEnrollments" :key="idx">
                <td>{{ idx + 1 }}</td>
                <td class="tdC">{{ item.matricula }}<span :title="$filters.dateFormat(item.reubicacion)"
                                                          class="p-badge p-badge-warning p-m-1" v-if="item.reubicacion">
                Reubicada
              </span></td>
                <td class="tdC">{{ item.asesor }}</td>
                <td class="tdC">{{ item.cargo }}</td>
                <td class="tdC">{{ item.org }}</td>
                <td class="tdC">{{ item.estrategia }}</td>
                <td class="tdC">{{ item.filial }}</td>
                <td class="tdC">{{ item.zone }}</td>
                <td class="tdC">{{ item.tipo_cobro }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </div>
  <div v-else>
    <h5 class="card-header text-center"><b>ATENCIÓN!!</b></h5>
    <div class="card-body">
      <p class="card-title">El acceso a esta opción no se encuentra autorizado</p>
      <Button label="Regresar" class="p-button-danger p-mr-2"
              @click="$router.push('/admin')"/>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import bouncer from "../../../helpers/bouncer";
import catalogService from "../../core/service/catalog.service";
import service from "../service/report.service";
import FileSaver from 'file-saver';
import moment from "moment";

export default {
  mixins: [bouncer],
  name: "ConsolidateEnrollmentReport",
  data() {
    return {
      loading: false,
      country: null,
      subsidiary: null,
      dateStart: null,
      dateEnd: null,
      countries: [],
      itemsEnrollments: [],
      tbData: [],
      service: service('consolidate-enrollment'),
      serviceDetail: service('consolidate-enrollment-detail'),
    }
  },
  mounted() {
    if (this.canAccess('rp_consolidate_enrollments')) {
      this.$store.dispatch('bread/setItems', [
        {label: 'Consolidado diario de Producción', url: "javascript:void(0);"}]);
      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
        if (!this.isAdmin) {
          this.country = this.countries[0];
        }
      });

    }
  },
  methods: {
    totalItems(cell) {
      let total = 0;
      this.tbData.forEach((item) => {
        if (item.zone !== '----') {
          total += item[cell];
        }
      });
      return total;
    },
    getCountries() {
      catalogService.get(`select/countries/subsidiaries-mw`).then(response => {
        this.countries = response.data;
        this.loading = false;
      });
    },
    getData() {
      this.loading = true;
      this.service.getConsolidateEnrollment({
        country: this.country?.id,
        date_start: moment(this.dateStart).format("YYYY-MM-DD"),
        date_end: moment(this.dateEnd).format("YYYY-MM-DD"),
      }).then(response => {
        const matriz = response.data?.matriz ?? [];
        this.tbData = Object.values(matriz[this.country.id]['details']);
      }).catch(err => {
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      }).finally(() => this.loading = false);
    },
    getDataSubsidiary() {
      if (this.country?.id == null || this.subsidiary?.id == null || this.dateStart == null || this.dateEnd == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Todos los campos son obligatorios',
          life: this.$utils.toastLifeTime()
        });
        return;
      } else {
        this.serviceDetail.getConsolidateEnrollmentDetail({
          country: this.country.id,
          subsidiary: this.subsidiary.id,
          date_start: moment(this.dateStart).format("YYYY-MM-DD"),
          date_end: moment(this.dateEnd).format("YYYY-MM-DD"),
        }).then(response => {
          this.itemsEnrollments = response.data.matriz;
          this.loading = false;
        }).catch(err => {
          const message = err.response.data;
          let error = this.$utils.formatError(message);
          this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
        }).finally(() => this.loading = false);
      }

    },

    exportReportSubsidiary(command) {
      if (this.country?.id == null || this.subsidiary?.id == null || this.dateStart == null || this.dateEnd == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Todos los campos son obligatorios',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.serviceDetail.getConsolidateEnrollmentDetail({
        country: this.country.id,
        subsidiary: this.subsidiary.id,
        date_start: moment(this.dateStart).format("YYYY-MM-DD"),
        date_end: moment(this.dateEnd).format("YYYY-MM-DD"),
        export: command
      }).then(x => {
        if (command === 'PDF') {
          this.info = x.data;
          var byteCharacters = atob(x.data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: "application/pdf"});
          this.loading = false;
          FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
        } else {
          this.loading = false;
          window.open(x.data.url_file)
        }
      }).catch((err) => {
        this.loading = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
    },

    exportReport(command) {
      if (this.country?.id == null) {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Todos los campos son obligatorios',
          life: this.$utils.toastLifeTime()
        });
        return;
      }
      this.loading = true;
      this.service.getConsolidateEnrollment({
        country: this.country?.id,
        date_start: moment(this.dateStart).format("YYYY-MM-DD"),
        date_end: moment(this.dateEnd).format("YYYY-MM-DD"),
        export: command
      }).then(x => {
        if (command === 'PDF') {
          this.info = x.data;
          var byteCharacters = atob(x.data);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var blob = new Blob([byteArray], {type: "application/pdf"});
          this.loading = false;
          FileSaver.saveAs(blob, "NaturaEnglishReport.pdf");
        } else {
          this.loading = false;
          window.open(x.data.url_file)
        }
      }).catch((err) => {
        this.loading = false;
        const message = err.response.data;
        let error = this.$utils.formatError(message);
        this.$toast.add({severity: 'error', summary: 'Error', detail: error, life: this.$utils.toastLifeTime()});
      })
    }
  },
  computed: {
    ...mapState('auth', ['isAdmin']),
    ...mapGetters('bread', ['breadcrumb']),
    subsidiaries() {
      const subsidiaries = this.country ? this.country.subsidiaries : [];
      let elements = [];
      if (subsidiaries.length > 0) {
        elements = [{
          id: 0,
          employee_id: 0,
          city_id: subsidiaries[0].city_id,
          name: "Todas las Filiales",
          created_at: null,
          deleted_at: null,
          laravel_through_key: subsidiaries[0].laravel_through_key,
          updated_at: null
        }, ...subsidiaries]
      }
      return elements;
    },
  }
}
</script>

<style>
.p-dropdown .p-dropdown-trigger {
  height: 33px;
}

div.tableC > div > div > table {
  table-layout: auto !important;
}
.df {
  margin-left: 3px !important;
  margin-right: 4px !important;

}

.table-responsive {
  overflow-x: auto;
}

thead tr:nth-child(1) th {
  background: white;
  position: sticky;
  top: 0;
  z-index: 10;
}

.thC {
  background: #f8f9fa !important;
}

.tdC {
  padding: 4px !important;
  text-align: center;
}
.p-dropdown-label {
  text-align: left;
}
.bold-row {
  font-weight: bold;
}

.block-ui {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #00000054;
  z-index: 99;
}

.block-ui-item {
  position: absolute;
  font-size: 3em;
  left: 50%;
  top: 50%;
}
</style>
